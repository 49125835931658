import { Link, NavLink, useMatches } from "@remix-run/react";
import isEmpty from "lodash/isEmpty";
import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ContactUsButton from "../ContactUsButton";

import ImageResponsive from "~/components/atoms/ImageResponsive";
import Typo from "~/components/atoms/Typo";
import { IMediaImageUrls } from "~/entities/media";
import { useScrollTop } from "~/hooks/use-scroll-top";
import { ILink, IMenuItem } from "~/types";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { Slug } from "~/utilities/enums/Slug";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface HeaderProps {
  logo?: IMediaImageUrls;
  menus: IMenuItem[];
  topButton?: ILink;
  className?: string;
  areColorsSame?: boolean;
}

export type Ref = HTMLDivElement;

const Header = forwardRef<Ref, HeaderProps>(
  ({ logo, menus, topButton, className, areColorsSame = true }, ref) => {
    const scrolled = useScrollTop();
    const { t } = useTranslation();
    const matches = useMatches();
    const isProjectPage = matches.some(
      (match) =>
        match.pathname.startsWith(`${Slug.PROJECTS}/`) &&
        match.pathname !== Slug.PROJECTS
    );
    const isSectionsPage = matches.some(
      (match) =>
        match.pathname.startsWith(`${Slug.SECTIONS}/`) &&
        match.pathname !== Slug.SECTIONS
    );

    const [isFixed, setIsFixed] = useState<boolean>(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setIsFixed(scrolled);
      }, 400);

      return () => clearTimeout(timer);
    }, [scrolled]);

    return (
      <header
        ref={ref}
        data-name="Header"
        className={cn(
          `sticky top-0 z-100 block bg-headerBackground py-4 transition-all duration-400`,
          !isSectionsPage && "lg:hidden",
          isProjectPage && areColorsSame
            ? `bg-backgroundPageProject`
            : `bg-headerBackground`,
          isFixed && "w-full border-b shadow-header ",
          className
        )}
      >
        <div className="container flex items-center justify-between gap-x-12">
          {logo && (
            <Link to={Slug.HOME}>
              <ImageResponsive
                imageData={logo}
                alt="Brand Logo"
                className="h-[34px] w-auto origin-left"
                zoom={1.3}
                displayDisclaimer={false}
              />
            </Link>
          )}
          <div className="flex items-center gap-x-12">
            {!isEmpty(menus) &&
              menus.map((menu, index) => (
                <NavLink key={index} to={menu.href}>
                  {({ isActive, isPending }) => (
                    <Typo
                      tag={ETypoTag.SPAN}
                      variant={ETypoVariant.HEADER_16}
                      color={ETypoColor.HEADER_TEXT}
                      className={cn(
                        "text-center transition-all duration-200 hover:text-headerTextHover",
                        isPending || isActive
                          ? "font-bold text-headerTextActive"
                          : ""
                      )}
                    >
                      {menu.title}
                    </Typo>
                  )}
                </NavLink>
              ))}
            <ContactUsButton label={topButton?.label || t("contact_us")} />
          </div>
        </div>
      </header>
    );
  }
);

Header.displayName = "Header";

export default Header;
